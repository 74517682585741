import React from "react"
import Layout from "../components/layout/Layout"
import Cta from "../components/sections/Cta"
import Distributors from "../components/sections/Distributors"
import Shop from "../components/shop/Shop"
import Main from "../components/pageLayout/Main"
import Sidebar from "../components/pageLayout/Sidebar"
import Content from "../components/pageLayout/Content"
import Banner from "../components/header/Banner"
import SideShop from "../components/shop/SideShop"
import SideContact from "../components/contact/SideContact"
import SEO from "../components/SEO"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer' 

export default () => {
  const data = useStaticQuery(graphql`
    query {
      prodejna: contentfulAsset(title: { eq: "Prodejna" }) {
        fluid(maxWidth: 1600, quality: 50) {
          ...GatsbyContentfulFluid
        }
      }
      about: contentfulOFirme(nadpis: {eq: "O firmě Agotop"}) {
        dlouhyPopis {
          json
        }
      }
    }
  `)
  
  return (
    <Layout>
      <SEO title="O firmě Agotop" />
      <Banner
        heading="O firmě Agotop"
        img={data.prodejna.fluid}
      />
      <Main>
        <Sidebar>
          <SideShop />
          <SideContact hide={true} />
        </Sidebar>
        <Content>
          <div>
            <h3 className="no-line">Agotop</h3>
            {documentToReactComponents(data.about.dlouhyPopis.json)}
          </div>
        </Content>
      </Main>
      <Cta text="shop" />
      <Shop secondary hideUnderlay/>
      <Distributors />
    </Layout>
  )
}
