import React from "react"
import styles from "./SideContact.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import SideInfo from "../sideinfo/SideInfo"

const getContact = graphql`
  query {
    allContentfulKontakt {
      edges {
        node {
          telefon
          mail
          adresa
        }
      }
    }
  }
`

const SideContact = ({ hide }) => {
  const {
    allContentfulKontakt: { edges },
  } = useStaticQuery(getContact)
  const { telefon, mail, adresa } = edges[0].node

  return (
    <div id="kontakt" className={`${styles.sideContact} ${hide ? "hide" : ""}`}>
      <h3 className="sideHeading">Kontakt</h3>
      <div className={styles.infoWrapper}>
        <SideInfo heading="Telefon" link={telefon} linkType="tel:" />
        <SideInfo heading="E-mail" link={mail} linkType="mailto:" />
        <SideInfo heading="Adresa:" info={adresa} />
      </div>
    </div>
  )
}

export default SideContact

SideContact.propTypes = {
  hide: PropTypes.bool,
}
