import React from 'react'
import styles from './SideShop.module.scss'
import { graphql, useStaticQuery} from 'gatsby'
import PropTypes from 'prop-types';

const getContent = graphql`
  query {
    allContentfulProdejna {
      edges {
        node {
          pondeliPatek
          sobota
          nedele
        }
      }
    }
  }
`

const SideShop = ({hide}) => {
  
  const { allContentfulProdejna : {edges} } = useStaticQuery(getContent);
  const { pondeliPatek, sobota, nedele, } = edges[0].node;

   return (
    <div className={`${styles.sideShop} ${hide ? "hide" : ''}`}>
     <h3 className="sideHeading">Otevírací doba</h3>
     <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <h5 className={styles.heading}>Pondělí - pátek:</h5>
          <div className={styles.detail}>{pondeliPatek}</div>
        </div>
        <div className={styles.info}>
          <h5 className={styles.heading}>Sobota:</h5>
          <div className={styles.detail}>{sobota}</div>
        </div>
        <div className={styles.info}>
          <h5 className={styles.heading}>Neděle a svátky:</h5>
          <div className={styles.detail}>{nedele}</div>
        </div>
      </div>
    </div>
  )
}

export default SideShop

SideShop.propTypes = {
  hide: PropTypes.bool
}